import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VModal from "vue-js-modal";
import store from "./store";
//import VueLodash from 'vue-lodash'
import "./plugins/axios";
import { BootstrapVue } from "bootstrap-vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Echo from "laravel-echo";
import MdComponents from 'md-components';

window.io = require("socket.io-client");
if (window.location.hostname.includes("localhost")) {
  window.Echo = new Echo({
    broadcaster: "socket.io",
    host: window.location.hostname + ":" + location.port
    //host: 'localhost:6001' // Line for local development from stefan
  });
} else {
  //web.meinelohnabrechnung.at
  //development.meinelohnabrechnung.at
  window.Echo = new Echo({
    broadcaster: "socket.io",
    host: window.location.hostname
  });
}

// Added this comment, to test if the Git trigger in CI works

/* function showNotification() {
  const notification = new Notification("Neue Nachricht von ML", {
    body: "BODY CONTENT"
  });
}

if (Notification.permission === "granted") {
  showNotification();
} else if (Notification.permission !== "denied") {
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      showNotification();
    }
  });
} */

Vue.config.productionTip = false;
Vue.use(VModal, {
  dialog: true
});
Vue.use(BootstrapVue);
//Vue.use(VueLodash)
Vue.use(VueReCaptcha, {
  siteKey: "6LdK8IUUAAAAACoVDRzjCYhdJIm_hPFKhEiAgyYn",
  loaderOptions: {
    autoHideBadge: true
  }
});
Vue.use(MdComponents);

import helpers from "./helpers";

Vue.use(helpers, store);

const bar = () => import("./components/uicomponents/bar.vue");
const label = () => import("./components/uicomponents/label.vue");
const button = () => import("./components/uicomponents/button.vue");
const emotlist = () => import("./components/uicomponents/emotlist.vue");
const menutabs = () => import("./components/uicomponents/menutabs.vue");
const datatable = () => import("./components/uicomponents/datatable.vue");
const datatablesimple = () => import("./components/uicomponents/datatablesimple.vue");
const monthlyclosingsummary = () => import("./components/uicomponents/monthlyClosing.vue");
const datepicker = () => import("./components/uicomponents/datepicker/datepicker.vue");
const headerdate = () => import("./components/uicomponents/headerdate.vue");
const tickertext = () => import("./components/uicomponents/tickertext.vue");
const datamapper = () => import("./components/uicomponents/datamapper.vue");
const rosterday = () => import("./components/uicomponents/rosterday.vue");
const timemarks = () => import("./components/uicomponents/timemarks.vue");
const listlabel = () => import("./components/uicomponents/listlabel.vue");
const menudetails = () => import("./components/menucontext/menudetails.vue");
const headerbuttonstack = () => import("./components/uicomponents/headerbuttonstack.vue");
const burgermenu = () => import("./components/uicomponents/burgermenu.vue");
const employeeentry = () => import("./components/uicomponents/employeeentry.vue");
const colorselection = () => import("./components/uicomponents/colorselection.vue");
const employeepicture = () => import("./components/uicomponents/employeepicture.vue");
const datatablefilter = () => import("./components/uicomponents/datatablefilter.vue");
const datatablepaging = () => import("./components/uicomponents/datatablepaging.vue");
const datatabletextfilter = () => import("./components/uicomponents/datatabletextfilter.vue");
const monthlyholidayentry = () => import("./components/uicomponents/monthlyholidayentry.vue");
const dateselectionheader = () => import("./components/uicomponents/dateselectionheader.vue");

Vue.component("bar", bar);
Vue.component("labelc", label);
Vue.component("buttonc", button);
Vue.component("emotlist", emotlist);
Vue.component("menutabs", menutabs);
Vue.component("datatable", datatable);
Vue.component("datatablesimple", datatablesimple);
Vue.component("monthlyclosingsummary", monthlyclosingsummary);
Vue.component("datepicker", datepicker);
Vue.component("headerdate", headerdate);
Vue.component("tickertext", tickertext);
Vue.component("datamapper", datamapper);
Vue.component("rosterday", rosterday);
Vue.component("timemarks", timemarks);
Vue.component("listlabel", listlabel);
Vue.component("menudetails", menudetails);
Vue.component("headerbuttonstack", headerbuttonstack);
Vue.component("burgermenu", burgermenu);
Vue.component("employeeentry", employeeentry);
Vue.component("colorselection", colorselection);
Vue.component("employeepicture", employeepicture);
Vue.component("datatablepaging", datatablepaging);
Vue.component("datatablefilter", datatablefilter);
Vue.component("datatabletextfilter", datatabletextfilter);
Vue.component("monthlyholidayentry", monthlyholidayentry);
Vue.component("dateselectionheader", dateselectionheader);

const loginpanel = () => import("./components/login/loginpanel.vue");
const profilepanel = () => import("./components/profile/profilepanel.vue");

Vue.component("loginpanel", loginpanel);
Vue.component("profilepanel", profilepanel);

const employeedocuments = () => import("./components/employeecontext/EmployeeDocuments.vue");
const documenttemplates = () => import("./components/employeecontext/DocumentTemplates.vue");
Vue.component("employeedocuments", employeedocuments);
Vue.component("documenttemplates", documenttemplates);

const employeewagedata = () => import("./components/employeecontext/EmployeeWageData.vue");
Vue.component("employeewagedata", employeewagedata);

const EmployeeDuplicateCleanup = () => import("./components/employeecontext/EmployeeDuplicateCleanup.vue");
const EmployeeImportJobs = () => import("./components/employeecontext/EmployeeImportJobs.vue");
const EmployeeExportOverview = () => import("./components/employeecontext/EmployeeExportOverview.vue");
const employeedata = () => import("./components/employeecontext/EmployeeData.vue");
const EmployeeDisplayOptions = () => import("./components/employeecontext/EmployeeDisplayOptions.vue");
const BMDIDImport = () => import("./components/employeecontext/BMDIDImport.vue");
const CollectiveAgreements = () => import("./components/employeecontext/CollectiveAgreements.vue");
const ReportsMain = () => import("./components/reportcontext/ReportsMain.vue");
const ReportOverview = () => import("./components/reportcontext/ReportOverview.vue");
const ReportEmployeeCrossLocation = () => import("./components/reportcontext/ReportEmployeeCrossLocation.vue");
const ReportAbsences = () => import("./components/reportcontext/ReportAbsences.vue");
const ReportOverviewVariable = () => import("./components/reportcontext/ReportOverviewVariable.vue");
const ReportBmdExportSums = () => import("./components/reportcontext/ReportBmdExportSums.vue");
const PersonnelMasterSheet = () => import("./components/reportcontext/PersonnelMasterSheet.vue");
Vue.component("EmployeeDuplicateCleanup", EmployeeDuplicateCleanup);
Vue.component("EmployeeImportJobs", EmployeeImportJobs);
Vue.component("EmployeeExportOverview", EmployeeExportOverview);
Vue.component("employeedata", employeedata);
Vue.component("employeedisplayoptions", EmployeeDisplayOptions);
Vue.component("BMDIDImport", BMDIDImport);
Vue.component("CollectiveAgreements", CollectiveAgreements);
Vue.component("ReportsMain", ReportsMain);
Vue.component("ReportOverview", ReportOverview);
Vue.component("ReportEmployeeCrossLocation", ReportEmployeeCrossLocation);
Vue.component("ReportAbsences", ReportAbsences);
Vue.component("ReportOverviewVariable", ReportOverviewVariable);
Vue.component("ReportBmdExportSums", ReportBmdExportSums);
Vue.component("PersonnelMasterSheet", PersonnelMasterSheet);

const CompanySettings = () => import("./components/companysettingcontext/CompanySettings.vue");
const EmployeeSyncSettings = () => import("./components/companysettingcontext/EmployeeSyncSettings.vue");
const FinancialAccountingGroups = () => import("./components/companysettingcontext/FinancialAccountingGroups.vue");
const WageTypes = () => import("./components/companysettingcontext/WageTypes.vue");
const IndividualWageTypes = () => import("./components/companysettingcontext/IndividualWageTypes.vue");
const AverageValues = () => import("./components/companysettingcontext/AverageValues.vue");
const EmploymentModels = () => import("./components/companysettingcontext/EmploymentModels.vue");
const UserRoles = () => import("./components/companysettingcontext/UserRoles.vue");
const BMDMapping = () => import("./components/companysettingcontext/BMDMapping.vue");
const NotificationOverview = () => import("./components/notifications/NotificationOverview.vue");
const EldaClearingOverview = () => import("./components/companysettingcontext/EldaClearingOverview.vue");
const eldaClearingDetails = () => import("./components/companysettingcontext/eldaClearingDetails.vue");
Vue.component("CompanySettings", CompanySettings);
Vue.component("EmployeeSyncSettings", EmployeeSyncSettings);
Vue.component("FinancialAccountingGroups", FinancialAccountingGroups);
Vue.component("WageTypes", WageTypes);
Vue.component("IndividualWageTypes", IndividualWageTypes);
Vue.component("AverageValues", AverageValues);
Vue.component("EmploymentModels", EmploymentModels);
Vue.component("UserRoles", UserRoles);
Vue.component("BMDMapping", BMDMapping);
Vue.component("NotificationOverview", NotificationOverview);
Vue.component("EldaClearingOverview", EldaClearingOverview);
Vue.component("eldaClearingDetails", eldaClearingDetails);

const SuperadminNewEmployeeMail = () => import("./components/admincontext/SuperadminNewEmployeeMail.vue");
const SuperadminVersions = () => import("./components/admincontext/SuperadminVersions.vue");
const SuperadminCompany = () => import("./components/admincontext/SuperadminCompany.vue");
const SuperadminStore = () => import("./components/admincontext/SuperadminStore.vue");
const SuperadminEmployee = () => import("./components/admincontext/SuperadminEmployee.vue");
const SuperadminEnums = () => import("./components/admincontext/SuperadminEnums.vue");
const SuperadminEmployeeSyncSettings = () => import("./components/admincontext/SuperadminEmployeeSyncSettings.vue");
const SuperadminTransferService = () => import("./components/admincontext/SuperadminTransferService.vue");
const SuperadminCollectiveAgreements = () => import("./components/admincontext/SuperadminCollectiveAgreements.vue");
Vue.component("SuperadminNewEmployeeMail", SuperadminNewEmployeeMail);
Vue.component("SuperadminVersions", SuperadminVersions);
Vue.component("SuperadminCompany", SuperadminCompany);
Vue.component("SuperadminStore", SuperadminStore);
Vue.component("SuperadminEmployee", SuperadminEmployee);
Vue.component("SuperadminEnums", SuperadminEnums);
Vue.component("SuperadminEmployeeSyncSettings", SuperadminEmployeeSyncSettings);
Vue.component("SuperadminTransferService", SuperadminTransferService);
Vue.component("SuperadminCollectiveAgreements", SuperadminCollectiveAgreements);

/*
const ExportBuilder = () => import("./components/exportcontext/ExportBuilder.vue");
Vue.component('ExportBuilder', ExportBuilder);
*/
const ExportMonthly = () => import("./components/exportcontext/ExportMonthly.vue");
Vue.component("ExportMonthly", ExportMonthly);
const ExportIndividualWageTypes = () => import("./components/exportcontext/ExportIndividualWageTypes.vue");
Vue.component("ExportIndividualWageTypes", ExportIndividualWageTypes);
const ExportDaily = () => import("./components/exportcontext/ExportDaily.vue");
Vue.component("ExportDaily", ExportDaily);
const ExportPersonnelProvisions = () => import("./components/exportcontext/ExportPersonnelProvisions.vue");
Vue.component("ExportPersonnelProvisions", ExportPersonnelProvisions);
const ExportDailyActualShifts = () => import("./components/exportcontext/ExportDailyActualShifts.vue");
Vue.component("ExportDailyActualShifts", ExportDailyActualShifts);

const ExportSotecDashboard = () => import("./components/exportcontext/ExportSotecDashboard.vue");
Vue.component("ExportSotecDashboard", ExportSotecDashboard);

const ComparisonHoliday = () => import("./components/comparecontext/ComparisonHoliday.vue");
Vue.component("ComparisonHoliday", ComparisonHoliday);

const AbsenceExport = () => import("./views/AbsenceExport.vue");
const ISTExport = () => import("./views/ISTExport.vue");
Vue.component("AbsenceExport", AbsenceExport);
Vue.component("ISTExport", ISTExport);

const LandingPage = () => import("./views/LandingPage.vue");
Vue.component("LandingPage", LandingPage);

const input = () => import("./components/uicomponents/generic/input.vue");
Vue.component("inputc", input);

const checkbox = () => import("./components/uicomponents/generic/checkbox.vue");
Vue.component("checkbox", checkbox);

Vue.component("aboutpopup", require("./components/aboutpopup.vue").default);

import $ from "jquery";

// use moment's german locale
const moment = require('moment');
require('moment/locale/de');

Vue.use(require('vue-moment'), {
    moment,
});

Vue.directive("tooltip", {
  bind: function (el, binding, vnode) {
    el.addEventListener("mouseenter", function () {
      if (!binding.value) return;
      if (typeof binding.value === "string") {
        window.app.ToggleTooltip(el, {
          toggled: true,
          header: binding.value
        });
      } else if (Object.prototype.toString.call(binding.value) == "[object Function]") {
        //for generated string you have to wrap them in functions example: GetAmountWrapper() { return  () => { return view.GetAmount(date) }}
        if (binding.value()) {
          if (typeof binding.value() === "string") {
            window.app.ToggleTooltip(el, {
              toggled: true,
              header: binding.value()
            });
          } else {
            window.app.ToggleTooltip(el, binding.value());
          }
        }
      } else {
        window.app.ToggleTooltip(el, binding.value);
      }
    });
    el.addEventListener("mousemove", function () {
      window.app.RepositionTooltip();
    });
    // el.addEventListener("DOMNodeRemoved", function () {
    //   window.app.ToggleTooltip(null, {
    //     toggled: false,
    //     header: "",
    //     type: "",
    //     componentState: null
    //   });
    // });
    // replace dom node removed with the following:
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (!mutation.removedNodes) return;

        const hasElement = !![ ...mutation.removedNodes ].find(e => e === el);
        if (hasElement) {
            window.app.ToggleTooltip(null, {
                toggled: false,
                header: '',
                type: '',
                componentState: null,
            });
            // console.log('element removed');
        }
      });
    });
    observer.observe(el, { attributes: true, childList: true, characterData: true });

    el.addEventListener("mouseleave", function () {
      window.app.ToggleTooltip(null, {
        toggled: false,
        header: "",
        type: "",
        componentState: null
      });
    });
  }
});

Vue.directive("header-position-lock", {
  bind: function (el, binding, vnode) {
    requestAnimationFrame(function () {
      let scrollContainer = el.parentNode;
      if (scrollContainer.parentNode && scrollContainer.parentNode.classList.contains("component-container")) scrollContainer = scrollContainer.parentNode;
      scrollContainer.addEventListener("scroll", function ($event) {
        el.style.setProperty("margin-left", scrollContainer.scrollLeft + "px", "important");
        el.style.setProperty("margin-top", scrollContainer.scrollTop + "px", "important");
      });
    });
  }
});

// prepare axios prototype for api calls
window.axios = require("axios");
var baseUrl = window.location.protocol + "//" + window.location.hostname;
console.log(baseUrl);
var port = window.location.port;
if (![80, 443].includes(port)) {
  baseUrl += ":" + port;
}
if (!baseUrl.endsWith("/")) baseUrl += "/";
var apiBaseUrl = baseUrl + "api";
console.log(apiBaseUrl);

var apiInstance = axios.create({
  baseURL: apiBaseUrl
});

apiInstance.interceptors.request.use((config) => {
  return updateRequestConfig(config);
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      // || status === 500) {
      //console.log("SOFT LOGOUT DISABLED !");
      softLogoutUser(baseUrl);
    }
    return Promise.reject(error);
  }
);

Vue.prototype.api = apiInstance;

new Vue({
  store: store,
  router: router,
  render: (h) => h(App)
}).$mount("#app");

function updateRequestConfig(config, keySecret = null) {
  let companyId = store.state.companyId;
  let fullUrl = config.baseURL + config.url;
  var url = config.url;
  if (url.includes("?")) {
    url += "&";
  } else {
    url += "?";
  }
  url += "companyId=" + companyId;
  config.url = url;
  config.headers.Authorization = "Bearer " + store.state.jwt;
  config.headers["Content-Type"] = "application/json";
  config.headers["X-Requested-With"] = "XMLHttpRequest";

  if (keySecret != null) {
    var CryptoJS = require("crypto-js");
    var moment = require("moment");

    var timestamp = moment().format();
    var method = config.method.toUpperCase();
    url = config.baseURL + config.url;
    let apipath = url.substring(url.indexOf("/api"));
    let turl = apipath.replace(/^.*\/\/[^\/]+/, "");

    var signature = [method, turl, timestamp].join(",");

    // hash and base64 encode it
    let hash = CryptoJS.HmacSHA1(signature, mdCoreSecret).toString(CryptoJS.enc.Base64);
    config.headers["x-api-key"] = hash;
    config.headers["x-api-key-timestamp"] = timestamp;
  }

  return config;
}

function softLogoutUser(targetUrl) {
  console.log("Unauthorized API call, permforming soft logout.");
  //localStorage.removeItem('md_token');
  //localStorage.removeItem('selected_company');
  //localStorage.removeItem('selectedStores');
  window.location.href = targetUrl;
}
