<template>
    <div class="data-fill" v-bind:class="{'display-only': displayOnly}">
        <div class="container-data">
            <div class="description" v-if="description" v-html="description">
            </div>
            <div class="entry" v-for="(entry, indexE) in entries" :key="indexE" v-bind:class="{hidden: CheckHidden(entry), divider: entry.divider, flat: entry.flat, flex: entry.flexGroup, 'flex-fixed': entry.flexFixed, 'time-big' : entry.timeBig, 'flex-divider' : entry.flexDivider, 'slider-margin' : entry.type != 'sliderdiv' && entry.slider}" v-bind:style="[{'margin-bottom': indexE === entries.length - 1 ? '10px' : ''}, entry.styles]">
                <div class="label" v-if="entry.label && (!entry.placeholder || entry.forceLabel)" v-bind:style="[GetMaxLabelWidth(entry), entry.labelStyles]">{{entry.label}}
                  <div v-if="entry.info" v-tooltip="entry.info" class="fas fa-circle-info" />
                </div>
                <div class="data" v-bind:class="{disabled: entry.disabled, uxdisabled: entry.uxdisabled}" v-bind:style="[entry.dataStyles]">
                    <dropdownsearch v-if="entry.dropdownSearchState" :state="entry.dropdownSearchState" @item-selected="UpdateDataFill"/>
                    <i class="fas fa-check-circle checkbox" v-if="entry.type === 'bool'" v-bind:class="{false: !entry.value}" v-on:click="entry.value = !entry.value, CheckAllEntriesVisibility(), UpdateDataFill()"></i>
                    <i class="fas fa-calendar-alt" v-if="entry.type === 'datearray'" v-on:click="OpenDatePickerArray($event, entry)"></i>

                    <!-- checkbox to enable/disable optional date fields -->
                    <i class="fas fa-check-circle checkbox" v-if="entry.type === 'date' && (entry.required !== undefined && !entry.required)" v-bind:class="{false: !entry.showDateField}" v-on:click="UpdateOptionalDate($event, entry), CheckAllEntriesVisibility(), UpdateDataFill()"></i>
                    <input v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required)) && !entry.hideDateText" min="1" v-bind:max="entry.value ? entry.value.daysInMonth() : 0" v-model="entry.date" type="number" class="label timeframe" style="margin-left: 12px;" v-on:keyup="UpdateDateValue($event, entry)"/>
                    <span v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required)) && !entry.hideDateText" style="margin-bottom: 2px">.</span>
                    <input v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required)) && !entry.hideDateText" min="1" max="12" v-model="entry.month" type="number" class="label timeframe" v-on:keyup="UpdateDateValue($event, entry)"/>
                    <span v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required)) && !entry.hideDateText" style="margin-bottom: 2px">.</span>
                    <input v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required)) && !entry.hideDateText" min="1900" max="2100" v-model="entry.year" type="number" class="label timeframe year" v-on:keyup="UpdateDateValue($event, entry)"/>

                    <i class="fas fa-calendar" v-if="((entry.type === 'date' || entry.type === 'datetimeframe') && (entry.showDateField || entry.required === undefined || entry.required))" v-on:click="OpenDatePicker($event, entry)"></i>
                    <input v-if="entry.type === 'timeframe' || entry.type === 'datetimeframe'" min="0" max="24" v-model="entry.hours" type="number" class="label timeframe" v-on:keyup="UpdateTimeValue($event, entry)"/>
                    <div class="label hour" v-if="entry.type === 'timeframe' || entry.type === 'datetimeframe'">:</div>
                    <input v-if="entry.type === 'timeframe' || entry.type === 'datetimeframe'" min="0" max="59" v-model="entry.minutes" type="number" class="label timeframe" v-on:keyup="UpdateTimeValue($event, entry)"/>
                    <div class="fas fa-angle-right label hour" v-if="(entry.type === 'timeframe'  || entry.type === 'datetimeframe')&& entry.startTime"></div>

                    <div class="toggle-list" v-if="entry.type === 'togglelist'" type="number">
                        <div class="toggle" v-for="(toggle, indexT) in entry.toggleEntries" v-on:click="ToggleListItem(entry, toggle)" v-bind:class="{selected: toggle.selected}" :key="indexT">{{toggle.label}}</div>
                         <buttonc class="toggle-all" v-bind:class="{selected: toggleAll}" v-if="!entry.selectSingle" label="Alle" @click="ToggleAllListItems(entry)" style="height: 20px;"/>
                    </div>
                    <div class="file-input" v-if="entry.type === 'file'">
                        <buttonc icon="fa-upload" type="square" @click="SelectFiles('file'+indexE)"/>
                        <small style="margin-left: 16px;">{{entry.value ? entry.value[0].name : 'Keine Datei ausgewählt'}}</small>
                        <input class="file" type="file" :id="'file'+indexE" v-if="entry.type === 'file'" :accept="entry.accept" @change ="UpdateFiles($event, entry)" hidden/>
                    </div>

                    <div class="slider" v-if="entry.type === 'sliderdiv'">
                        <div v-if="entry.slider.labelled" class="slider-value">
                            {{ entry.value != undefined? entry.value : '' }}
                        </div>
                        <div class="bar" v-bind:class="{labelled: entry.slider.labelled}">
                            <div class="handle" v-bind:style="{left: (100 * ((entry.value - entry.slider.min) / (entry.slider.max - entry.slider.min))) + '%'}" v-on:mousedown="SliderStart($event, entry)">
                                <div class="amount" v-bind:style="{opacity: sliderCurrent != null && sliderCurrent.key == entry.key ? '1' : ''}">
                                    {{entry.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <slider class="slider" v-if="entry.type !== 'sliderdiv' && entry.slider" :state="entry.slider" @callback="SliderUpdate"></slider>
                    <input class="input numeric" v-if="entry.type === 'numeric'" type="text" v-bind:min="entry.min" v-bind:max="entry.max" v-model="entry.value" v-on:keydown="NumberCheck($event, entry)"  v-on:input="ErrorCheck()" v-on:blur="UpdateDataFill()"/>
                    <input class="input numeric text" v-if="entry.type === 'text' && !entry.dropdownSearchState && !entry.slider" v-bind:class="{copytext: entry.copyButton}" v-bind:id="entry.key" v-bind:maxlength="entry.length" v-model="entry.value" v-bind:placeholder="entry.placeholder" v-bind:readonly="entry.readonly" v-on:input="ErrorCheck()" v-on:blur="UpdateDataFill()"/>
                    <button class="copy" v-if="entry.type === 'text' && !entry.dropdownSearchState && !entry.slider && entry.copyButton" v-tooltip="entry.copyButtonTooltip ? entry.copyButtonTooltip : 'Text in die Zwischenablage kopieren'" v-on:click="copyToClipboard(entry.key)">
                      <i class="fal fa-copy" style="padding-right: 2px"></i>
                    </button>
                    <input class="input numeric text" v-if="entry.type === 'password' && !entry.dropdownSearchState && !entry.slider" type="password" v-bind:maxlength="entry.length" v-model="entry.value" v-bind:placeholder="entry.placeholder" v-on:input="ErrorCheck()" v-on:blur="UpdateDataFill()"/>
                    <p v-if="entry.type === 'output'" v-bind:style="[entry.valueStyles]">{{entry.value}}</p>
                    <div class="color" v-if="entry.type === 'color'" v-bind:style="{background: entry.value}" @click="OpenColorPicker(entry)"></div>
                    <!--<labelc type="input-error" v-if="errors[entry.key]" :text="errors[entry.key].text"/>
                    <buttonc type="input-error" v-tooltip="errors[entry.key].tooltip" icon="fa-exclamation-circle" v-bind:class="[errors[entry.key].type]" v-if="errors[entry.key]"/>-->
                </div>
            </div>
            <div class="entry"  >
              <ul style="text-align: left">
                <li style="list-style-type: none; color: red; font-size: 12px;" v-for="(entry, indexE) in errors" :key="indexE">
                  <i class="fas fa-exclamation-circle" v-tooltip="entry.tooltip" style="color: red;"></i> {{entry.label}} - {{entry.text}}
                </li>
              </ul>

              <!--<labelc type="input-error"  :text="entry.text" style="width:50%;"/>
              <buttonc type="input-error" v-tooltip="entry.tooltip" icon="fa-exclamation-circle" v-bind:class="entry.type"/>-->
            </div>
            <div class="button accept entry flex-fixed" v-if="state && state.selectCallback" v-bind:class="{disabled: loading || Object.values(errors).length > 0}" v-on:click="Accept()">
                <div class="label" v-if="!loading">{{ positiveButtonLabel }}</div>
                <buttonc v-if="loading" type="rectangle red footer" icon="fa-spinner" style="margin-left: 0;"/>
            </div>
            <div class="button delete entry flex-fixed" v-if="deleteCallback" v-bind:class="{disabled: loading || Object.values(errors).length > 0}" v-on:click="Delete()">
                <div class="label" v-if="!loading"> {{ negativeButtonLabel }}</div>
                <buttonc v-if="loading" type="rectangle green footer" icon="fa-spinner" style="margin-right: 0;"/>
            </div>
            <div v-if="errors['error_div']" class="error-div label">
                <i class="fas fa-exclamation-circle"></i>
                <labelc v-if="errors['error_div']" :text="errors['error_div'].text"/>
                <i class="fas fa-exclamation-circle"></i>
            </div>
        </div>
        <datepickerarray :state="datepickerarrayState" ref="datepickerarray" v-on:datepickerarray-dates-picked="OnDateArrayPicked"></datepickerarray>
        <datepicker :state="datepickerState" ref="datepicker" v-on:datepicker-date-picked="PickDate"></datepicker>
    </div>
</template>

<script>
    import datepickerarray from './datepickerarray.vue'
    import datepicker from './datepicker.vue'
    import dropdownsearch from './dropdownsearch.vue'
    import slider from './slider.vue'
    export default {
        name: "datafill",
        components: {
            datepickerarray,
            datepicker,
            dropdownsearch,
            slider,
        },

        created(){
            var message = this;
            if(this.state) for(var x in this.state) this[x] = this.state[x];
        },
        destroyed () {
        },
        data(){
            return{
                header: '',
                description: null,
                type: '',
                timer: 0,
                hidden: false,
                loading: false,
                entries: [],
                data: {},
                toggleAll: false,
                selectCallback: function(data){},
                deleteCallback: null,
                parentComponent: null,
                positiveButtonLabel: 'Akzeptieren',
                negativeButtonLabel: 'Löschen',
                datepickerarrayState: {
                    toggled: false,
                    monthView: true,
                    posx: window.mouse.x,
                    posy: window.mouse.y,
                    date: this.$helpers.getCurrentMonthMomentUTC(),
                    selectedDate: this.$helpers.getCurrentMonthMomentUTC(),
                    dateArray: [],
                    yearView: true,
                },
                datepickerState: {
                    toggled: false,
                    monthView: true,
                    posx: window.mouse.x,
                    posy: window.mouse.y,
                    date: this.$helpers.getCurrentMonthMomentUTC(),
                    selectedDate: this.$helpers.getCurrentMonthMomentUTC(),
                    yearView: false,
                },
                sliderStartPos: {x: 0, y: 0},
                sliderCurrent: null,
                sliderBox: null,
                entriesLastValues: {},
                displayOnly: false,
                remainAfterSubmit: false,
                errorCheck: null, // array of functions [
                                  //                        (entries) => {
                                  //                           if(entries.hasOwnProperty('password') && entries['password'].value === '')
                                  //                              return {key: 'password', type: 'error', text: 'Please input password'}
                                  //                           else return null
                                  //                        }
                                  //                    ]
                errors: {},
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal){
                    let view = this;
                    for(let x in newVal) this[x] = newVal[x];
                    if(this.entries && !this.entries.length) {
                        for(let [k,v] of Object.entries(this.entries)){
                            if(!v.key) v.key = k;
                        }
                        this.entries = Object.values(this.entries);
                    }
                    this.entries.filter(e => e.type === 'datetimeframe').forEach(e => {
                        if(e.value) {
                            if (!e.value.year) e.value = view.$helpers.getMomentFromStringTZ(e.value);
                            e.year = e.value.format('YYYY');
                            e.month = e.value.format('MM');
                            e.date = e.value.format('DD');
                            e.hours = e.value.format('HH');
                            e.minutes = e.value.format('mm');
                        } else {
                            e.disabled = true;
                            e.date = e.month = e.year = '-';
                            e.hours = e.minutes = '-';
                        }
                    });
                    this.entries.filter(e => e.type === 'date').forEach(e => {
                        if(e.value) {
                            if (!e.value.year) e.value = view.$helpers.getMomentFromStringTZ(e.value);
                            e.year = e.value.format('YYYY');
                            e.month = e.value.format('MM');
                            e.date = e.value.format('DD');
                        } else {
                            if (!(e.required !== undefined && !e.required)) {
                              e.disabled = true;
                            }
                            e.date = e.month = e.year = '-';
                        }
                    });
                    this.entries.filter(e => e.type === 'timeframe').forEach(e => {
                        if(e.value) {
                            if(!e.value.year) e.value = view.$helpers.getMomentFromStringTZ(e.value);
                            e.hours = e.value.format('HH');
                            e.minutes = e.value.format('mm');
                        } else {
                            e.disabled = true;
                            e.hours = e.minutes = '-';
                        }
                    });
                    this.entries.filter(e => e.dropdownSearchState).forEach(e => {
                        if(e.dropdownSearchState.selectMultiple && !e.dropdownSearchState.selectedItems) e.dropdownSearchState.selectedItems = [];
                        e.dropdownSearchState.key = e.key;
                        let onceSelected = e.dropdownSearchState.onceSelected;
                        if(!e.dropdownSearchState.selectMultiple) {
                            if (e.dropdownSearchState.selectFirst && e.dropdownSearchState.items && e.dropdownSearchState.items.length > 0) e.value = e.dropdownSearchState.items[0].value;
                            if (e.dropdownSearchState.selectIf && e.dropdownSearchState.items && e.dropdownSearchState.items.length > 0 && e.dropdownSearchState.items.find(i => e.dropdownSearchState.selectIf(i))) e.value = e.dropdownSearchState.items.find(i => e.dropdownSearchState.selectIf(i)).value;
                        } else {
                            let temp = [];
                            if (e.dropdownSearchState.selectFirst && e.dropdownSearchState.items && e.dropdownSearchState.items.length > 0) temp.push(e.dropdownSearchState.items[0].value);
                            if (e.dropdownSearchState.selectIf && e.dropdownSearchState.items && e.dropdownSearchState.items.length > 0 && e.dropdownSearchState.items.filter(i => temp.indexOf(i) === -1 && e.dropdownSearchState.selectIf(i)).length > 0) e.dropdownSearchState.items.filter(i => temp.indexOf(i) === -1 && e.dropdownSearchState.selectIf(i)).forEach(i => temp.push(i.value));
                            e.value = temp;
                        }
                        e.dropdownSearchState.onceSelected = ($event, item, dropdown) => {
                            let entry = view.entries.find(e => e.key === dropdown.key);
                            if(entry){
                                if(!dropdown.selectMultiple) {
                                    if(item) entry.value = item.value;
                                    else entry.value = null;
                                } else entry.value = dropdown.selectedItems.map(i => i.value);
                            }
                            if(onceSelected) onceSelected($event, item, dropdown);
                        };
                    });
                    this.entries.filter(e => e.slider).forEach(e => {
                        if(!e.slider.object) {
                            e.slider.object = e;
                        }
                    });
                    view.ComputeEntries();
                    requestAnimationFrame(function(){
                        view.CheckAllEntriesVisibility();
                        view.SetPreviousValues();
                        view.AddFlexDividers();
                        view.ErrorCheck();
                    });
                },
            }
        },
        props:{
            state: Object,
        },
        methods: {
          NumberCheck($event, entry){
            if($event.key === 'Delete' || $event.key === 'BackSpace' || this.$helpers.IsKeyNameNavigationType($event.key)) return;
            if($event.key.length === 1 && isNaN(parseInt($event.key))) $event.preventDefault();
            let change = parseInt($event.key);
            let el = $event.currentTarget;
            let selection = el.value.substr(el.selectionStart, el.selectionEnd - el.selectionStart);
            let value = $event.currentTarget.value;
            if(selection !== ''){
              value = value.substring(0, el.selectionStart) + selection + value.substring(el.selectionEnd);
            }
            else {
              value += change;
            }

            if($event.currentTarget.attributes.min && $event.currentTarget.attributes.min.value && $event.currentTarget.attributes.max && $event.currentTarget.attributes.max.value){
                if(parseInt(value) < parseInt($event.currentTarget.attributes.min.value)){
                $event.currentTarget.value = parseInt($event.currentTarget.attributes.min.value);
                $event.preventDefault();
                } else if(parseInt(value) > parseInt($event.currentTarget.attributes.max.value)){
                $event.currentTarget.value = parseInt($event.currentTarget.attributes.max.value);
                $event.preventDefault();
                }
            }

            entry.value = $event.currentTarget.value;
            this.ComputeEntry(entry);
          },
            OpenColorPicker(entry){
                this.$helpers.OpenColorSelection(
                    (color) => {
                        entry.color = color;
                    },
                    {
                        colorSelection: entry.colorSelection,
                        colorSelected: entry.color,
                        colorBlacklist: entry.colorBlacklist,

                    }
                );
            },
            SelectFiles(id) {
                document.getElementById(id).click()
            },
            UpdateFiles(event, entry){
                console.log(event.target.files);
                entry.value = event.target.files;
            },
            GetMaxLabelWidth(entry){
                let view = this;
                if(this.entries && !this.entries.length) this.entries = Object.values(this.entries);
                if(entry.labelStyles && entry.labelStyles.width) return {};
                return {width: ((this.entries.filter(e => (e.hidden === undefined || e.hidden === false || (e.hidden instanceof Function && e.hidden(view.entries, entry) !== true)) && e.label).reduce((total, current) => {return total.label.length > current.label.length ? total : current }).label.length * 7) + 5) + 'px !important'}

            },
            AddFlexDividers(){
                let temp = [];
                for(let x = 0; x < this.entries.length; x++){
                    temp.push(this.entries[x]);
                    if(this.entries[x].flexRowEnd) temp.push({flexDivider: true});
                }
                this.entries = temp;
            },
            SetPreviousValues(){
                let view = this;
                view.entriesLastValues = {};
                this.entries.filter(e => !e.divider).forEach(e => {
                    if(e.dropdownSearchState){

                        let dropdown = view.$children.find(c => c.state != undefined && c.state.key === e.key);
                        if(!dropdown.selectMultiple) {
                            if(dropdown.selectedItem) view.entriesLastValues[e.key] = dropdown.selectedItem.value;
                            else view.entriesLastValues[e.key] = null;
                        } else view.entriesLastValues[e.key] = dropdown.selectedItems.map(i => i.value);
                    } else if(e.value && e.value.year) {
                        view.entriesLastValues[e.key] = e.value.format('x');
                    } else {
                        view.entriesLastValues[e.key] = e.value;
                    }
                });
            },
            CheckPreviousValue(entry){
                let view = this;
                if(entry.dropdownSearchState){
                    let dropdown = view.$children.find(c => c.state.key === entry.dropdownSearchState.key);
                    if(!dropdown.selectMultiple) {
                        if(dropdown.selectedItem) return view.entriesLastValues[entry.key] === dropdown.selectedItem.value;
                        else return view.entriesLastValues[entry.key] === null;
                    } else return dropdown.selectedItems.map(i => i.value).every(val => view.entriesLastValues[entry.key].find(i => i === val));
                } else if(entry.value && entry.value.year) {
                    return view.entriesLastValues[entry.key] === entry.value.format('x');
                } else {
                    return view.entriesLastValues[entry.key] === entry.value;
                }
                return false;
            },
            UpdateDataFill(){
                let view = this;
                /*let updatedVals = this.entries.filter(e => !e.divider).filter(e => !view.CheckPreviousValue(e));
                updatedVals.forEach(e => view.ComputeEntry(e));*/
                this.entries.filter(e => e.computed).forEach(e => view.ComputeEntry(e));
                requestAnimationFrame(function(){
                    view.SetPreviousValues();
                });
                this.ErrorCheck();
            },
            ComputeEntries(){
                for(let x in this.entries.filter(e => e.computed)) {
                    let entry = this.entries[x];
                    this.ComputeEntry(entry);
                }
            },
            ComputeEntry(entry){
              // console.log("ComputeEntry");
                let view = this;
                let index = view.entries.find(e => e.key === entry.key);
                index = view.entries.indexOf(index);
                if(index) {
                    let lastVal = entry.value;
                    if (entry.computed) {
                        let computed = entry.computed;
                        let entryBeforeUpdate = entry;
                        view.entries[index] = computed(view, lastVal, entryBeforeUpdate);
                        view.entries[index].computed = computed;
                        if(view.entries[index].dropdownSearchState) {
                            let state = view.entries[index].dropdownSearchState;
                            state.key = view.entries[index].key;
                            if(!state.selectMultiple) {
                                if(!state.items.find(i => i.value === lastVal)) {
                                    if (state.selectFirst && state.items && state.items.length > 0) view.entries[index].value = state.items[0].value;
                                    if (state.selectIf && state.items && state.items.length > 0 && state.items.find(i => state.selectIf(i))) view.entries[index].value = state.items.find(i => state.selectIf(i)).value;
                                }
                            } else {
                              if(view.entries[index].value && Array.isArray(view.entries[index].value)) view.entries[index].value = view.entries[index].value.filter(v => state.items.find(i => i.value === v));

                            }
                        }

                    }
                }
                // this.$forceUpdate();
                this.ErrorCheck();
            },
            SliderUpdate(val, object){
                let entry = this.entries.find(e => e === object); //slider object is entry but better safe than sorry
                if(entry) {
                    entry.value = val;
                    entry.slider.value = val;
                }
                this.UpdateDataFill();
            },
            SliderStart($event, entry){
                this.sliderBox = $event.currentTarget.parentNode.getBoundingClientRect();
                this.sliderCurrent = entry;
                this.sliderStartPos = window.mouse;
                window.mouse.click = true;
                this.SliderWatch();
            },
            SliderWatch(){
                let view = this;
                let box = this.sliderBox;
                this.sliderCurrent.value = (((this.sliderCurrent.slider.max - this.sliderCurrent.slider.min) * (window.mouse.x - box.left) / box.width) + this.sliderCurrent.slider.min);
                this.sliderCurrent.value = parseInt(this.sliderCurrent.value);
                if(this.sliderCurrent.value < this.sliderCurrent.slider.min) this.sliderCurrent.value = this.sliderCurrent.slider.min;
                if(this.sliderCurrent.value > this.sliderCurrent.slider.max) this.sliderCurrent.value = this.sliderCurrent.slider.max;
                if(window.mouse.click){
                    requestAnimationFrame(function(){view.SliderWatch()});
                } else {
                    this.SliderEnd();
                }
            },
            SliderEnd(){
                this.sliderCurrent = null;
                this.sliderBox = null;
            },
            CheckAllEntriesVisibility(){
                if(this.entries && !this.entries.length) this.entries = Object.values(this.entries);
                this.entries.forEach((e) => {
                    this.CheckHidden(e);
                    this.CheckSliderValue(e);
                });
                this.$forceUpdate();
            },
            CheckSliderValue(e){
                if(e.slider && !e.hidden){
                    if(e.value < e.slider.min) e.value = e.slider.min;
                    else if(e.value > e.slider.max) e.value = e.slider.max;
                }
            },
            CheckHidden(entry){
                if(!entry) return false;
                if(entry.hidden instanceof Function) return (entry.hidden(this.entries, entry));
                if(entry.hiddenBoundTo != undefined){
                    this.entries.forEach((e) => {
                        if(entry.hiddenBoundTo == e.key) entry.hidden = e.value;
                    });
                }
                if(entry.hiddenBoundToInverse != undefined){
                    this.entries.forEach((e) => {
                        if(entry.hiddenBoundToInverse == e.key) entry.hidden = !e.value;
                    });
                }
                if(entry.hiddenBoundToValue != undefined){
                    this.entries.forEach((e) => {
                        if(entry.hiddenBoundToValue.key == e.key) {
                            if(e.type == 'dropdownsearch'){
                                if(entry.hiddenBoundToValue.value == undefined || e.dropdownSearchState.selectedItem.value == undefined){
                                    entry.hidden = true;
                                }else{
                                    entry.hidden = entry.hiddenBoundToValue.value == e.dropdownSearchState.selectedItem.value;;
                                }
                            }else{
                                if(entry.hiddenBoundToValue.value == undefined || e.value == undefined){
                                    entry.hidden = true;
                                }else{
                                    entry.hidden = entry.hiddenBoundToValue.value == e.value;
                                }
                            }
                        }
                    });
                }
                if(entry.hiddenBoundToValueInverse != undefined){
                    this.entries.forEach((e) => {
                        if(entry.hiddenBoundToValueInverse.key == e.key) {
                            if(e.type == 'dropdownsearch'){
                                if(entry.hiddenBoundToValueInverse.value == undefined || e.dropdownSearchState.selectedItem.value == undefined){
                                    entry.hidden = true;
                                }else{
                                    entry.hidden = entry.hiddenBoundToValueInverse.value != e.dropdownSearchState.selectedItem.value;;
                                }
                            }else{
                                if(entry.hiddenBoundToValueInverse.value == undefined || e.value == undefined){
                                    entry.hidden = true;
                                }else{
                                    entry.hidden = entry.hiddenBoundToValueInverse.value == e.value;
                                }
                            }
                        }
                    });
                }
                return entry.hidden;
            },
            ToggleListItem(entry, toggle){
                if(entry.selectSingle){
                    entry.toggleEntries.forEach(t => t.selected = false);
                    toggle.selected = true;
                } else {
                    toggle.selected = !toggle.selected;

                }
            },
            ToggleAllListItems(entry){
                this.toggleAll = !this.toggleAll
                entry.toggleEntries.forEach(t => t.selected = this.toggleAll);
            },
            Init(header, entries, selectCallback, parentComponent){
                this.header = header;
                this.entries = entries;
                this.selectCallback = selectCallback;
                this.parentComponent = parentComponent;
                var view = this;

            },
            PickDate(date){
                // console.log("PickDate", this.entryToUpdate);
                this.entryToUpdate.year = date.year();
                this.entryToUpdate.month = date.month() + 1; //hurr I'm momentjs november is the tenth month of the year
                this.entryToUpdate.date = date.date();
                this.entryToUpdate.value = date.clone();
                this.$forceUpdate();
            },
            OnDateArrayPicked(dateArray){
                //actually since it's the same array and not a .slice it would work regardless though cancel doesn't cancel
            },
            OpenDatePicker($event, entry){
              let box = $event.currentTarget.getBoundingClientRect();
              this.entryToUpdate = entry;
              let view = this;
              this.$helpers.OpenDatepickerPopup(
                { viewsEnabled: ['D','C'], date: entry.value.clone() },
                {
                  OnDateSelect: (date) => { view.PickDate(date) },
                },
                { elRelative: $event ? $event.currentTarget : null });
            },
            OpenDatePickerArray($event, entry){
                var box = $event.currentTarget.getBoundingClientRect();
                this.datepickerarrayState = {
                    toggled: true,
                    monthView: false,
                    posx: box.right + 15,
                    posy: box.top,
                    date: this.$helpers.getMomentUTC().month(0).date(1),
                    selectedDate: this.$helpers.getMomentUTC().month(0).date(1),
                    dateArray: entry.value,
                    yearView: true,
                };
            },
            UpdateTimeValue($event, entry){
                entry.value.hours(entry.hours).minutes(entry.minutes);
                this.UpdateDataFill();
            },
            UpdateDateValue($event, entry){
                // console.log("UpdateDateValue", entry);
                entry.value.year(entry.year).month(entry.month - 1).date(entry.date);
                this.UpdateDataFill();
            },
            UpdateOptionalDate($event, entry){
                // console.log("UpdateOptionalDate", entry);
                entry.showDateField = !entry.showDateField;
                // if (!entry.showDateField) {
                //     entry.oldVal = entry.value;
                //     entry.value = null;
                // } else {
                //     entry.value = entry.oldVal;
                // }
                this.UpdateDataFill();
            },
            Accept(){
                var data = {};
                for(var x in this.entries){
                    if(this.entries[x].key) {
                        var entry = this.entries[x];

                        if (entry.type === 'dropdownsearch') {
                            let dropdown = this.$children.find(c => c.state === entry.dropdownSearchState);
                            if (entry.selectMultiple || entry.dropdownSearchState.selectMultiple) {
                                data[entry.key] = dropdown.selectedItems.map(i => i.value);
                            } else {
                                if (dropdown.selectedItem) {
                                    data[entry.key] = dropdown.selectedItem.value;
                                } else {
                                    data[entry.key] = null;
                                }

                            }
                        } else if (entry.type === 'togglelist') {
                            if(!entry.selectSingle) data[entry.key] = entry.toggleEntries.filter(t => t.selected).map(i => i.value);
                            else {
                                let selected = entry.toggleEntries.find(t => t.selected) ;
                                if(selected) data[entry.key] = selected.value;
                            }
                        } else if (entry.type === 'date') {
                            // Remove value from date entry if the field is optional and hidden
                            if((entry.required !== undefined && !entry.required) && !entry.showDateField) {
                                entry.value = null;
                            }
                            data[entry.key] = entry.value;
                        } else data[entry.key] = entry.value;
                    }
                }

                //this.entries = [];
                if(this.loading) return;
                // console.log(this.errorCheck);
                if(this.errorCheck != null){
                    this.errors = {};
                    for(let key in this.errorCheck){
                        let check = this.errorCheck[key];
                        let errorResult = check(data);
                        if(errorResult) this.errors[errorResult.key] = errorResult;
                    }
                }

                if(Object.values(this.errors).length !== 0) return;


                if(this.selectCallback) this.selectCallback(data, this); //selectCallback sends component to allow server error checking without closing the popup example:

                                                                         //selectCallback: (data, datafill) => {
                                                                         //    datafill.loading = true;     //lock datafill accept/delete buttons until response is resolved
                                                                         //    view.changePassword(data.password, data.newpassword).then((response) => {
                                                                         //        view.lastname = data.lastname;
                                                                         //        datafill.Toggle(); //response success, close datafill
                                                                         //    }).catch((error) => {
                                                                         //        datafill.loading = false;    //error, unlock button display error message with this.$helpers.error(..., ...) or errors object like below
                                                                         //        if(error.response != undefined && error.response.data.message != undefined){
                                                                         //            datafill.errors = {};
                                                                         //            datafill.errors['password'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                                                         //            datafill.errors['newpassword'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                                                         //            datafill.errors['newpasswordconfirm'] = {key: 'password', type: 'error', text: 'Änderung konnte nicht durchgeführt werden', tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'};
                                                                         //        }
                                                                         //    })
                                                                         //},
                if(this.loading) return;
                if(this.$parent.Toggle) this.$parent.Toggle(false);
                else this.hidden = !this.remainAfterSubmit;
            },
            ErrorCheck(){
              if(!this.errorCheck || Object.values(this.errorCheck).length === 0) return;
              var data = {};
              for(var x in this.entries){
                if(this.entries[x].key) {
                    var entry = this.entries[x];

                    if (entry.type === 'dropdownsearch') {
                        let dropdown = entry.dropdownSearchState;
                        if (dropdown) {
                          if (entry.selectMultiple || entry.dropdownSearchState.selectMultiple) {
                            // if (dropdown.selectedItems) {
                            //   data[entry.key] = dropdown.selectedItems.map(i => i.value);
                            // } else {
                              data[entry.key] = dropdown.items.filter(i => i.selected).map(i => i.value);
                              // console.log("data[" + entry.key + "]", data[entry.key]);
                            // }
                          } else {
                              if (dropdown.selectedItem) {
                                  data[entry.key] = dropdown.selectedItem.value;
                              } else {
                                  data[entry.key] = null;
                              }

                          }
                        }
                    } else if (entry.type === 'togglelist') {
                        if(!entry.selectSingle) data[entry.key] = entry.toggleEntries.filter(t => t.selected).map(i => i.value);
                        else {
                            let selected = entry.toggleEntries.find(t => t.selected) ;
                            if(selected) data[entry.key] = selected.value;
                        }
                    } else data[entry.key] = entry.value;
                }
              }
              this.errors = {};
              for(let key in this.errorCheck){
                  let check = this.errorCheck[key];
                  let errorResult = check(data);
                  // console.log("errorResult", errorResult);
                  if(errorResult) this.errors[errorResult.key] = errorResult;
              }
              // console.log("errors", this.errors);
            },
            Cancel(){
                this.entries = [];
                if(this.loading) return;
                if(this.callbackCancel) this.callbackCancel(this);
                if(this.parentComponent) this.parentComponent.Toggle(false);
                else this.hidden = !this.remainAfterSubmit;
            },
            Delete(){
                let view = this;
                var data = {};
                for(var x in this.entries){
                    if(this.entries[x].key) {
                        var entry = this.entries[x];

                        if (entry.type === 'dropdownsearch') {
                            if (entry.selectMultiple) {
                                data[entry.key] = entry.dropdownSearchState.selectedItems.map(i => i.value);
                            } else {
                                if (entry.dropdownSearchState.selectedItem) {
                                    data[entry.key] = entry.dropdownSearchState.selectedItem.value;
                                } else {
                                    data[entry.key] = null;
                                }

                            }
                        } else if (entry.type === 'togglelist') {
                            if(!entry.selectSingle) data[entry.key] = entry.toggleEntries.filter(t => t.selected).map(i => i.value);
                            else {
                                let selected = entry.toggleEntries.find(t => t.selected);
                                if(selected) data[entry.key] = selected.value;
                            }
                        } else if (entry.type === 'date') {
                            data[entry.key] = entry.value.date(entry.date).month(entry.month).year(entry.year);
                        } else if (entry.type === 'timeframe') {
                            data[entry.key] = entry.value.hours(entry.hours).minutes(entry.minutes);
                        } else if (entry.type === 'datetimeframe') {
                            data[entry.key] = entry.value.date(entry.date).month(entry.month).year(entry.year).hours(entry.hours).minutes(entry.minutes);
                        } else data[entry.key] = entry.value;
                    }
                }
                this.deleteCallback(data);
                if(this.$parent.Toggle) this.$parent.Toggle(false)
                else this.hidden = !this.remainAfterSubmit;
            },
            copyToClipboard(elementToCopy) {
                // Get the text field
                let copyText = document.getElementById(elementToCopy);

                // Select the text field
                copyText.select();
                copyText.setSelectionRange(0, 99999); // For mobile devices

                // Copy the text inside the text field
                navigator.clipboard.writeText(copyText.value);

                console.log("Copied the text: " + copyText.value);
                this.$helpers.info(
                    "Info",
                    "Text wurde in die Zwischenablage kopiert."
                );
            }
        },
    }
</script>

<style scoped>

.data-fill{
    text-align: center;
    max-height: 100%;
}

.description{
    text-align: left;
    padding: 10px;
    color: var(--contrast-4);
    display: block;
    clear: both;
    width: 100%;
}

.header{
    display: block;
    width: 100%;
    height: 36px;
    box-shadow: inset 0 -2px 0 -1px var(--contrast-2);
}

.header .label{
        font-size: 20px;
    width: 100%;
    text-align: left;
}

.entry{
    position: relative;
    display: flex;
    margin: 10px 0;
}

.entry.flat{
    margin: 0px 0;
}

.entry  > .label{
    display: flex;
    height: 25px;
    text-align: right;
    margin-right: 5px;
    white-space: pre;
    align-items: center;
    /*width: 80px;*/
    font-family: DistrictProLight;
    font-weight: 200;
    font-size: 14px;
}

.entry .data{
    display: flex;
    text-align: initial;
    flex: 1;
    align-self: center;
    align-items: center;
}

.entry.time-big .data{
    justify-content: center;
}


.entry:not(.time-big) .label.hour{
    line-height: 24px;
    margin-left: 2px;
}

.entry.time-big > label{
    justify-content: center;
}

.entry .data .checkbox{
    float: none;
    transform: scale(1.2);
    margin-right: 20px !important;

}
.entry .data input{
    width: 100%;
    font-size: 15px;
    font-family: DistrictProLight;
    font-weight: 400;
    padding: 2px 10px;
    height: 25px;
    background: none;
    border-radius: 0;
    box-shadow: inset 0 -2px 0 -1px var(--contrast-5);
}
.entry .data .file-input{
    height: 25px;
}

.entry .data .file-input .button {
    margin-top: 0px !important;
}

.entry .data.disabled input{
    box-shadow: none;
}

.entry .data.uxdisabled input{
    box-shadow: none;
}

.entry .data input.timeframe{
    width: 20px;
    display: inline-block;
    padding: 0;
    text-align: center;
    box-shadow: none;
}
.entry .data .dropdown-search{
    position: relative;
    margin-left: 0px;
    z-index: initial;
    width: 100%;
}
.button{
    margin: 0 7.5px;
    margin-bottom: 10px;
    min-width: 55px;
    background: var(--ml);
    box-shadow: 0 0 0 1px black;
    display: inline-flex;
    align-items: center;
    margin-top: 10px !important;
}

.button .label{
    color: white;
    justify-content: center;
    font-weight: 400;
    font-family: DistrictProLight;
    transform: none;
    margin: 0 10px;
    font-size: 12px;

}

.button:hover {
    background: var(--contrast-4);
}

.button:hover .label {

}

.entry .data input.timeframe.year{
    width: 40px;
}

.fa-calendar{
    color: var(--contrast-3);
    font-size: 20px;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
}

.disabled .fa-calendar{
    display: none;
}

.uxdisabled .fa-calendar{
    display: none;
}

.entry .toggle-list{

    position: relative;
    display: inline-block;
}

.entry .toggle-list .toggle{
    text-align: center;
    transition: .2s;
    height: 20px;
    width: 35px;
    margin-right: 1px;
    border-right: none;
    border-radius: 0;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: var(--contrast-2);
    font-size: 11px;
}

.entry .toggle-list .toggle:first-child{
    border-radius: 5px 0 0 5px;
}
.entry .toggle-list .toggle:last-child{
    border-radius: 0 5px 5px 0;
    margin-right: 0;
}
.entry .toggle-list .toggle:first-child:last-child{
    border-radius: 5px;
    margin-right: 0;
}

.entry .toggle-list .toggle:hover, .entry .toggle-list .toggle.selected, .entry .toggle-list .toggle-all:hover, .entry .toggle-list .toggle-all.selected{
    background: var(--ml);
    color: white;
}

.entry .toggle-list .toggle-all{
    height: 20px;
    background: var(--contrast-2);
    font-size: 11px;
}

.entry.flex{
    display: inline-flex;
}

.entry.flex-fixed{
    display: inline-flex;
    margin: auto;
    margin-right: 0px;
}

.entry.flex > .label, .entry.flex > .data{
    display: flex;
    align-items: center;
}
.entry.flex-fixed > .data{
    width: initial !important;
    }
.entry.divider{
    box-shadow: inset 0 -2px 0 -1px var(--contrast-3);
    margin: 10px 0;
    height: 1px;
}

.entry.time-big.end-time .data{

    justify-content: flex-end;
}

.entry.time-big > .data .timeframe{
    color: var(--ml);
    font-size: 50px;
    height: 50px;
    width: 60px;
    box-shadow: none;
    font-weight: 800;
    font-family: DistrictProBook;
}


.entry.time-big{
    margin-left: 15px;
    margin-right: 15px;
}

.entry.time-big > .data .hour{
    color: var(--contrast-2);
    font-size: 50px;
    width: 10px;
    height: 65px;
    margin: 0 5px;
    display: flex;
    align-items: flex-end;
}

.entry.time-big > .data .hour.fa-angle-right{
    font-size: 40px;
    position: absolute;
    cursor: initial;
    right: -20px;
    height: 50px;
    align-items: flex-end;
    margin-top: -5px;
}

.entry.time-big > .label{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    width: 100% !important;
    text-align: center;
    margin: auto;
    color: var(--contrast-2);
    font-size: 20px;
    line-height: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: DistrictProBook;

}

.entry.time-big.end-time > .label{
    right: -78px;
    left: 0;
}

.entry.time-big > .data{
    width: initial !important;
}

    .container-data{
        clear: both;
        min-width: 300px;
        overflow-y: auto;
        max-height: calc(70vh - 20px);
        padding: 15px;
    }

    .flex-divider{
    flex-basis: 100%;
    width: 0px;
    height: 0px;
    margin: 0;
    overflow: hidden;
    }

    .entry .input.text{
        box-shadow: 0 0px 0 1px var(--contrast-3);
        background: none;
        border-radius: 12px;
    }

    .entry .input.text.copytext {
      border-radius: 12px 0px 0px 12px;
      border-right: none;
    }

    .entry .copy {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 12px 12px 0px;
      border: none;
      margin-left: 3px;
      box-shadow: 0 0px 0 1px var(--contrast-3);
      background: none;
      padding: 4px 0px 5px 0px;
    }

    .entry.hidden{
        display: none;
        opacity: 0;
        pointer-events: none;
    }

    .entry.flex-fixed .label{
        width: initial;
    }

    .entry.end-time{
        justify-items: right;
    }

    .entry.flex > .label{
        width: initial !important;

    }

    .entry .data > .color{
        width: 35px;
        height: 15px;
        border-radius: 100px;
        -webkit-box-shadow: 0 0 0 1px var(--contrast-3);
        box-shadow: 0 0 0 1px var(--contrast-3);
        padding: 0;
        display: flex;
        position: relative;
        margin-top: 6px;
        cursor: pointer;
    }
    .entry .data > .color input{
        opacity: 0;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    .delete{
        margin-left: 10px !important;
        background: #e5154f;
    }
    .delete:hover{
        background: #ff6b8c;
    }

    .slider{
        position: relative;
        width: 100%;
        height: 20px;
    }

    .slider .slider-value {
        text-align: right;
        padding: 1px 10px 0px 0px;
        font-size: 12px;
        position: absolute;
        left: calc(100% - 30px);
        width: 30px;
        height: 20px;
        color: var(--col-label-default);
    }

    .slider .bar{
        position: absolute;
        left: 10px;
        width: calc(100% - 20px);
        height: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: var(--contrast-2);
        border-radius: 100px;
    }

    .slider .bar.labelled{
        width: calc(100% - 20px - 30px);
    }

.slider .bar .handle{
    position: absolute;
    height: 13px;
    width: 13px;
    background: var(--ml);

    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 100px;
    cursor: pointer;
    margin-left: -7.5px;
}


.slider .bar .handle:hover{
    background: #404040;
}

.slider .bar .handle .amount{
        position: absolute;
        margin: auto;
        display: inline-block;
        background: white;
        box-shadow: 4px 4px 5px 3px rgba(0,0,0,0.2);
        pointer-events: none;
        width: fit-content;
        padding: 5px;
        border-radius: 5px;
        transform: translate(-50%, -125%);
        opacity: 0;
        height: 25px;
        line-height: 13px;
        margin-left: 6px;
    }

    .slider:hover .bar .handle .amount{
        opacity: 1;
    }

.display-only .entry{
    pointer-events: none;
}

.display-only .entry .input{
    box-shadow: none;
}

.display-only .entry > .label{
    font-family: DistrictProBold;
}

.display-only .button.accept,.display-only .button.delete{
    display:none;
}

.entry.slider-margin{
    margin-top: 20px;
}

    .tooltip .data-fill .container-data{
        padding: 0;
        min-width: initial;
    }

.error-div {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0px 42px 16px 42px;
    text-align:center;
}

.error-div .label{
    color: var(--col-red-text);
    font-family: DistrictProThin !important;
}

.error-div .fas {
    color: var(--col-red-text);
}

</style>
